import { type ReactNode, useMemo } from 'react'

import type { Movie, Theater } from 'types/showtimes'
import useValidContext from './common'
import {
  CurrentShowtimesContext,
  CurrentShowtimesProvider,
} from './currentShowtimesProvider'

type CurrentMovieContextType = {
  movie: Movie
  matchingTheaters: Theater[]
  allTheaters?: Theater[]
}

export const useCurrentMovie = (): CurrentMovieContextType => {
  const { matchingShowtimes, allShowtimes } = useValidContext(
    CurrentShowtimesContext
  )
  if (!matchingShowtimes) {
    throw new Error(
      'useCurrentMovie must be used within a CurrentMovieProvider'
    )
  }
  return useMemo(
    () => ({
      movie: matchingShowtimes[0].movie,
      matchingTheaters: matchingShowtimes[0].theaters,
      allTheaters: allShowtimes?.[0]?.theaters,
    }),
    [allShowtimes, matchingShowtimes]
  )
}

type CurrentMovieProviderProps = {
  children: ReactNode | ReactNode[]
}

export function CurrentMovieProvider({ children }: CurrentMovieProviderProps) {
  return <CurrentShowtimesProvider>{children}</CurrentShowtimesProvider>
}
