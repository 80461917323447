import type { Dayjs } from 'dayjs'
import type { LatLngTuple } from 'leaflet'
import { useMemo } from 'react'
import { useCurrentMovie } from '../providers/currentMovieProvider'

export default function useShowtimesTheaterNearMe(date: Dayjs | undefined) {
  const { matchingTheaters } = useCurrentMovie()

  return useMemo(() => {
    const theatersAtDate = matchingTheaters.filter((t) =>
      t.showtimes.some(({ showtime }) => showtime.isSame(date, 'day'))
    )

    // compute LatLng center and bounding box to show all items on the map (default)
    const positions: LatLngTuple[] = theatersAtDate.map(
      ({ latitude, longitude }) => [latitude, longitude]
    )

    return { positions, theatersAtDate }
  }, [date, matchingTheaters])
}
