import { Space } from '@mantine/core'
import { useCallback, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import Footer from 'components/common/Footer'
import { useSearchShowtimes } from 'queries/showtimes'
import notificationSubscribe from 'queries/users'
import useDisplayStore from 'states/useDisplayStore'
import useSearchStore from 'states/useSearchStore'
import { globalFunc, globalVar } from 'utils/utils'
import Results from './Results'

type Props = {
  isVisible?: boolean
}

function Search({ isVisible = true }: Props) {
  const { setNbLogins } = useDisplayStore((state) => ({
    setNbLogins: state.setNbLogins,
  }))
  const searchParams = useSearchStore((state) => state.searchParams)
  const favoriteTheaterIds = useSearchStore((state) => state.favoriteTheaterIds)
  const showPrices = useSearchStore((state) => state.showPrices)
  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useSearchShowtimes(searchParams)
  const movies = results?.pages?.flat() || []

  // Only reset the search scroll when searchParams change
  const initialParams = useRef(searchParams)
  useEffect(() => {
    if (
      JSON.stringify(searchParams) !== JSON.stringify(initialParams.current)
    ) {
      window.scrollTo(0, 0)
    }
  }, [searchParams])

  const subscribe = useCallback(async () => {
    const token = globalVar<string>('expoPushToken')
    if (token) {
      const result = await notificationSubscribe(
        token,
        searchParams,
        favoriteTheaterIds,
        showPrices
      )
      if (result !== null) {
        setNbLogins(result.nb_logins)
      }
    }
  }, [searchParams, favoriteTheaterIds, showPrices, setNbLogins])

  useEffect(() => {
    if (globalVar('notifPermission') === 'undetermined') {
      const subscribeIfGranted = ({
        status,
        token,
      }: {
        status: string
        token: string | null
      }) => {
        window.notifPermission = status
        if (status === 'granted' && token) {
          window.expoPushToken = token
          subscribe()
        }
      }
      globalFunc('fetchNotifPermission', subscribeIfGranted)
    }
  }, [subscribe])

  // Subscribe to push notifications if the user has a token set
  useEffect(() => {
    if (globalVar('expoPushToken')) {
      subscribe()
    }
  }, [subscribe])

  return (
    <>
      <Helmet>
        <title>Recherche de séance - La Bobine</title>
        <meta property="og:url" content={`${window.location.href}/search`} />
      </Helmet>
      <div className="h-full min-h-screen relative">
        <Results
          movies={movies}
          hasMore={isVisible === true && (hasNextPage || isLoading)}
          loadNextPage={fetchNextPage}
        />
        <Space h={10} />
        <div className="mb-10">
          <Footer color="#495057" relative />
        </div>
        <Space h={10} />
      </div>
    </>
  )
}

export default Search
