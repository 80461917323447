import { Anchor, AspectRatio, Image } from '@mantine/core'
import trackEvent from 'queries/event'
import { memo, useCallback } from 'react'
import { FaPlayCircle } from 'react-icons/fa'
import type { Movie } from 'types/showtimes'

type Props = {
  movie: Movie
  className?: string
}

function TrailerVideo({ movie, className = '' }: Props) {
  const handlePlayTrailer = useCallback(() => {
    trackEvent('trailer_click', {
      id: movie.id,
      movieName: movie.titleVf,
      youtubeId: movie.trailerYoutubeId,
    })
  }, [movie.id, movie.titleVf, movie.trailerYoutubeId])

  if (!movie.trailerYoutubeId) {
    return null
  }

  return (
    <div className={className}>
      <Anchor
        href={`https://youtu.be/${movie.trailerYoutubeId}`}
        onClick={handlePlayTrailer}
        target="_blank"
        rel="noopener noreferrer"
      >
        <AspectRatio className="overflow-hidden" ratio={16 / 9}>
          <Image src={movie.trailerThumbnailPath} height="100%" />
        </AspectRatio>
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-10">
          <FaPlayCircle
            className="text-gray-200 stroke-[1.5] group-hover:text-white"
            size={60}
          />
        </div>
      </Anchor>
    </div>
  )
}

export default memo(TrailerVideo)
