import {
  Button,
  type ButtonProps,
  Tooltip,
  createPolymorphicComponent,
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import type { ReactNode } from 'react'

interface Props extends ButtonProps {
  icon: ReactNode
  text: string
  threshold: number
  showTooltip?: boolean
}

function ResponsiveButton({
  icon,
  text,
  threshold,
  showTooltip = true,
  ...props
}: Props) {
  const { ref, width } = useElementSize()
  const showText = width > threshold

  return (
    <Tooltip
      label={text}
      disabled={showText || !showTooltip}
      withArrow
      position="bottom"
    >
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        leftSection={showText ? icon : null}
      >
        {showText ? text : icon}
      </Button>
    </Tooltip>
  )
}

export default createPolymorphicComponent<'button', Props>(ResponsiveButton)
