import { List, Text } from '@mantine/core'
import type { Dayjs } from 'dayjs'
import LeafLet, { type LatLng } from 'leaflet'
import { Marker, Popup } from 'react-leaflet'
import { Link } from 'react-router-dom'
import type { Movie, Theater } from 'types/showtimes'
import getDistanceInMeters from '../../../utils/distance'
import TheaterAnchor from '../../theater/TheaterAnchor'

type Props = {
  date: Dayjs | undefined
  movie: Movie
  userPosition: LatLng
  theaters: Theater[]
}

export default function TheaterMarkers(props: Props) {
  const {
    movie,
    userPosition: { lat: usrLat, lng: usrLon },
    date,
    theaters,
  } = props
  return (
    <>
      {theaters.map((theater) => {
        const { latitude: thLat, longitude: thLon } = theater
        const distance =
          usrLat && usrLon
            ? getDistanceInMeters(
                { latitude: thLat, longitude: thLon },
                { latitude: usrLat, longitude: usrLon }
              )
            : null

        const pos = LeafLet.latLng(thLat, thLon)

        return (
          <div key={theater.name}>
            <Marker
              position={pos}
              title={theater.name}
              icon={
                new LeafLet.Icon({
                  iconUrl: '/theater_location.png',
                  iconSize: [40, 40],
                })
              }
            >
              <Popup>
                <div className="flex h-full items-center w-fit">
                  <div className="align-middle max-w-[120px] text-ellipsis">
                    <Text lineClamp={2}>{theater.name}</Text>
                    <List size="xs">
                      {theater.showtimes
                        .filter((s) => s.showtime.isSame(date, 'date'))
                        .map((s) => (
                          <List.Item key={s.id}>
                            <Link
                              to={`${movie.urlPath}/seance/${s.id}`}
                              state={{ hasPrev: true }}
                            >
                              {s.showtime.format('HH:mm')}
                            </Link>
                          </List.Item>
                        ))}
                    </List>
                  </div>
                  <TheaterAnchor
                    theater={{
                      ...theater,
                      distance: distance ?? theater.distance,
                    }}
                  />
                </div>
              </Popup>
            </Marker>
          </div>
        )
      })}
    </>
  )
}
