import { ActionIcon, Container, Flex, Stack } from '@mantine/core'
import ShareButton from 'components/common/ShareButton'
import useNavigationKeyboardEvents from 'hooks/useNavigationKeyboardEvents'
import React from 'react'
import { HiOutlineHome } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { Share } from 'tabler-icons-react'

type Props = {
  className?: string
  children?: React.ReactNode
  linkTitle: string
  url: string
}

function ModalHeader({
  className = '',
  children = null,
  linkTitle,
  url,
}: Props) {
  const navigate = useNavigate()

  useNavigationKeyboardEvents('/search')

  return (
    <Container size="xl" px={0} className={`w-full ${className}`}>
      <Flex className="w-full">
        <Stack
          gap={1}
          align="center"
          className="text-zinc-900 mx-5 center px-2 py-1 w-fit rounded-md cursor-pointer h-fit"
          onClick={() => navigate('/search')}
        >
          <ActionIcon size={35} variant="transparent" color="zinc.9">
            <HiOutlineHome size={35} />
          </ActionIcon>
        </Stack>
        {children}
        <div className="mr-5 ml-auto">
          <ShareButton url={url} text={linkTitle}>
            <Stack
              gap={1}
              align="center"
              className="center py-1 w-[75px] rounded-md cursor-pointer h-fit"
            >
              <ActionIcon size={32} variant="transparent" color="zinc.9">
                <Share size={32} />
              </ActionIcon>
            </Stack>
          </ShareButton>
        </div>
      </Flex>
    </Container>
  )
}

export default React.memo(ModalHeader)
