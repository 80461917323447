import LeafLet from 'leaflet'
import { useMemo } from 'react'
import useSearchStore from '../states/useSearchStore'
import { DEFAULT_LOCATIONS } from './useLocationService'

export default function useMyPosition() {
  const {
    latitude: usrLat,
    longitude: usrLon,
    range,
  } = useSearchStore((state) => state.searchParams)
  const hasPosition = usrLat != null && usrLon != null

  const userPosition = LeafLet.latLng(
    usrLat || DEFAULT_LOCATIONS.paris.latitude,
    usrLon || DEFAULT_LOCATIONS.paris.longitude
  )

  return useMemo(
    () => ({
      userPosition,
      hasPosition,
      range,
    }),
    [hasPosition, range, userPosition]
  )
}
