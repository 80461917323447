import LeafLet, { type Map as LeafletMap } from 'leaflet'
import { useCurrentMovie } from 'providers/currentMovieProvider'
import React, { useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import useMyPosition from '../../../hooks/useMyPosition'
import useShowtimesTheaterNearMe from '../../../hooks/useShowtimesTheaterNearMe'
import useDisplayStore from '../../../states/useDisplayStore'
import MyPosition from './MyPosition'
import RangeCircle from './RangeCircle'
import TheaterMarkers from './TheaterMarkers'

function ShowtimesNearMe() {
  const { movie } = useCurrentMovie()
  const date = useDisplayStore((state) => state.currentDate)
  const [map, setMap] = useState<LeafletMap | null>(null)
  const { userPosition, hasPosition, range } = useMyPosition()
  const { positions, theatersAtDate } = useShowtimesTheaterNearMe(date)

  const bounds = LeafLet.latLngBounds([...positions, userPosition]).pad(
    Math.sqrt(2) / 2
  ) // expend current bounds

  if (map != null) {
    // show all theaters in the map
    map.fitBounds(bounds)
  }

  return (
    <div
      className="w-hull h-full min-w-60 min-h-60"
      onTouchStart={(event) => event.stopPropagation()}
    >
      <MapContainer
        ref={setMap}
        className="w-full h-full"
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MyPosition userPosition={userPosition} showPosition={hasPosition} />
        <RangeCircle center={userPosition} range={range} />
        <TheaterMarkers
          theaters={theatersAtDate}
          movie={movie}
          userPosition={userPosition}
          date={date}
        />
      </MapContainer>
    </div>
  )
}

export default React.memo(ShowtimesNearMe)
