import { Text } from '@mantine/core'
import LeafLet, { type LatLng } from 'leaflet'
import { Marker, Popup } from 'react-leaflet'

type Props = {
  userPosition: LatLng
  showPosition: boolean
}

export default function MyPosition(props: Props) {
  const { userPosition, showPosition } = props
  if (!showPosition) {
    return null
  }

  const Icon = new LeafLet.Icon({
    iconUrl: '/location_marker.png',
    iconSize: [33, 50],
  })

  return (
    <Marker
      draggable={false}
      position={userPosition}
      title="Ma position"
      icon={Icon}
    >
      <Popup>
        <div className="flex h-full items-center w-fit">
          <div className="align-middle max-w-[120px] pr-4">
            <Text>Ma position</Text>
          </div>
        </div>
      </Popup>
    </Marker>
  )
}
