import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Anchor, Container } from '@mantine/core'
import dayjs from 'dayjs'
import { useIsMobile } from 'providers/isMobileProvider'
import trackEvent from 'queries/event'
import type ShowtimeContext from 'types/ShowtimeContext'
import {
  contextToEventData,
  generateIcsEvent,
  removeIcsEvent,
} from 'utils/calendar'
import { globalDataFunc, globalVar } from 'utils/utils'
import MessageModal from './MessageModal'

type AddToCalendarResponse = {
  status: 'success' | 'error'
  reason?: string
  message?: string
}

type Props = {
  children: React.ReactNode
  showtimeContext: ShowtimeContext
  className?: string
}

function CalendarButton({ children, showtimeContext, className = '' }: Props) {
  const { isMobileApp } = useIsMobile()
  const [errorType, setErrorType] = useState<string | null>(null)
  const eventData = useMemo(
    () => contextToEventData(showtimeContext),
    [showtimeContext]
  )
  const objectUrl = generateIcsEvent(eventData)

  useEffect(() => () => removeIcsEvent(objectUrl), [objectUrl])

  const trackClick = useCallback(() => {
    trackEvent('add_to_calendar', {
      showtimeId: showtimeContext.showtime.id,
      showtime: showtimeContext.showtime.showtime,
    })
  }, [showtimeContext])

  if (showtimeContext.endTime < dayjs()) {
    return null
  }

  if (isMobileApp) {
    if (!globalVar('addToCalendar')) {
      return null
    }

    return (
      <Container
        onClick={() => {
          trackClick()
          globalDataFunc(
            'addToCalendar',
            eventData,
            (data: AddToCalendarResponse) => {
              if (data.status === 'error') {
                trackEvent('add_to_calendar_error', data)
              }
              setErrorType(data.reason || null)
            }
          )
        }}
        className={className}
      >
        {errorType && (
          <MessageModal>
            {errorType === 'no_permission' ? (
              <>
                Pour accéder au calendrier, activez les autorisations dans les
                paramètres de votre téléphone :
                <br /> Applications &#x3009; Bobine &#x3009; Calendrier.
              </>
            ) : (
              "Impossible d'ajouter l'événement au calendrier."
            )}
          </MessageModal>
        )}
        {children}
      </Container>
    )
  }

  return (
    <Anchor
      onClick={trackClick}
      href={objectUrl}
      download={`${eventData.title.replace(/\s+/g, '_')}.ics`}
      className={className}
    >
      {children}
    </Anchor>
  )
}

export default React.memo(CalendarButton)
