import dayjs from 'dayjs'
import type { Movie, MovieKind } from 'types/showtimes'

export function buildMovieRatingUrlFor(
  kind: MovieKind,
  id: string
): string | undefined {
  switch (kind) {
    case 'allocine':
      return `https://allocine.fr/film/fichefilm_gen_cfilm=${id}.html`
    case 'senscritique':
      return `https://senscritique.com/film/page/${id}`
    case 'imdb':
      return `https://www.imdb.com/title/${id}`
    case 'mubi':
      return `https://mubi.com/fr/fr/films/${id}`
    default:
      return undefined
  }
}

export function getMaxRatingFor(kind: MovieKind) {
  return kind === 'allocine' ? 5 : 10
}

const NO_RATING_DISPLAY = '-'

export function buildRatingDisplayFor(movie: Movie, kind: MovieKind) {
  const rating = movie[`${kind}Rating`]
  if (kind === 'allocine' && movie.releaseDate?.isAfter(dayjs())) {
    // Hide Allocine rating for unreleased movies
    return NO_RATING_DISPLAY
  }
  return rating ? rating.toFixed(1) : NO_RATING_DISPLAY
}
