import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function useNavigateBack(defaultPath = '/search') {
  const navigate = useNavigate()
  const location = useLocation()

  return useCallback(() => {
    if (location.state?.hasPrev === undefined) {
      navigate(-1)
    } else {
      navigate(defaultPath, { state: { hasPrev: true } })
    }
  }, [defaultPath, location.state?.hasPrev, navigate])
}
