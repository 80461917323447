import type { LatLng } from 'leaflet'
import { Circle } from 'react-leaflet'

type Props = {
  range: number | undefined
  center: LatLng
}

export default function RangeCircle(props: Props) {
  const { range, center } = props
  if (range === undefined) {
    return null
  }
  return (
    <Circle
      center={center}
      radius={range * 1000}
      pathOptions={{ fillOpacity: 0 }}
    />
  )
}
