import { Flex, Grid, ScrollArea, Stack, Title } from '@mantine/core'
import PageTabs from 'components/common/PageTabs'
import MobileModalHeader from 'components/search/genericmodal/MobileModalHeader'
import { sumBy } from 'lodash'
import {
  CurrentMovieProvider,
  useCurrentMovie,
} from 'providers/currentMovieProvider'
import { useIsMobile } from 'providers/isMobileProvider'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { BsTicketPerforated, BsTicketPerforatedFill } from 'react-icons/bs'
import { MdMap, MdMovie, MdOutlineMap, MdOutlineMovie } from 'react-icons/md'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import MovieModalContent from './MovieModalContent'
import ShowtimesList from './ShowtimesList'
import ShowtimesNearMe from './ShowtimesNearMe'
import ShowtimesTab from './ShowtimesTab'
import SynopsisTab from './SynopsisTab'

type RouteParams = {
  movieId: string
  tab: string
  showtimeId?: string
}

type Props = {
  currentTab: string | undefined
}

export function MovieContent({ currentTab }: Props) {
  const location = useLocation()
  const { showtimeId } = useParams<RouteParams>()
  const { isMobile } = useIsMobile()
  const { movie, allTheaters } = useCurrentMovie()

  const displayTitle = movie.releaseDate
    ? `${movie.titleVf} (${movie.releaseDate.year()})`
    : movie.titleVf || ''

  const nbShowtimes = sumBy(allTheaters, 'showtimes.length')

  const helmet = (
    <Helmet>
      <title>{displayTitle} - Séances</title>
      <meta
        name="description"
        content={`Séances de ciné partout en France et en Belgique du film ${movie.titleVf}. ${movie.synopsis}`}
      />
      <meta property="og:title" content={displayTitle} />
      <meta property="og:description" content={movie.synopsis} />
      <meta property="og:image" content={movie.posterPath} />
      <meta
        property="og:url"
        content={`${window.location.origin}${movie.urlPath}`}
      />
      <link
        rel="canonical"
        href={`${window.location.origin}${movie.urlPath}`}
      />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Movie',
          name: movie.titleVf,
          image: movie.posterPath,
          duration: `PT${movie.duration}M`,
          genre: movie.genres,
          description: movie.synopsis,
          director: movie.director
            ?.split(',')
            .map((d) => ({ '@type': 'Person', name: d.trim() })),
          actor: movie.casting
            ?.split(',')
            .map((a) => ({ '@type': 'Person', name: a.trim() })),
          aggregateRating: movie.consolidatedRating &&
            movie.totalNbRatings && {
              '@type': 'AggregateRating',
              ratingValue: movie.consolidatedRating || 0,
              ratingCount: movie.totalNbRatings || 0,
              worstRating: 1,
              bestRating: 10,
            },
        })}
      </script>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
        integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
        crossOrigin=""
      />
      <script
        src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
        integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
        crossOrigin=""
      />
    </Helmet>
  )

  const showtimesTab = useMemo(
    () => (
      <ShowtimesTab>
        <ScrollArea offsetScrollbars className="h-full w-full" type="auto">
          <ShowtimesList />
        </ScrollArea>
      </ShowtimesTab>
    ),
    []
  )

  const movieHeader = useMemo(
    () => (
      <>
        <MobileModalHeader className="mb-2 text-zinc-900" />
        <h1
          className="font-semibold text-2xl uppercase truncate text-zinc-900"
          style={{
            fontFamily: 'Josefin Sans, sans-serif',
          }}
        >
          {movie.titleVf}
        </h1>
      </>
    ),
    [movie.titleVf]
  )

  const keyTabs = useMemo(
    () => [
      {
        subroute: undefined, // points to root
        title: 'Film',
        icon: <MdOutlineMovie size={27} className="mx-auto" />,
        selectedIcon: <MdMovie size={27} className="mx-auto" />,
        content: (
          <Stack>
            <MovieModalContent />
            <SynopsisTab />
          </Stack>
        ),
      },
      {
        subroute: 'seances',
        title: `Séances (${nbShowtimes})`,
        icon: <BsTicketPerforated size={30} className="mx-auto" />,
        selectedIcon: <BsTicketPerforatedFill size={30} className="mx-auto" />,
        content: (
          <Stack gap={1} className="w-full h-full p-[10px]">
            {movieHeader}
            {showtimesTab}
          </Stack>
        ),
      },
      {
        subroute: 'map',
        title: 'Carte',
        icon: <MdOutlineMap size={27} className="mx-auto" />,
        selectedIcon: <MdMap size={27} className="mx-auto" />,
        content: (
          <Stack gap={1} className="w-full h-full p-[10px]">
            {movieHeader}
            <ShowtimesTab>
              <ShowtimesNearMe />
            </ShowtimesTab>
          </Stack>
        ),
      },
    ],
    [movieHeader, nbShowtimes, showtimesTab]
  )

  if (isMobile) {
    return (
      <Flex className="h-[calc(var(--page-height))] bg-white">
        {helmet}
        <PageTabs root={movie.urlPath} currentTab={currentTab} tabs={keyTabs} />
      </Flex>
    )
  }

  if (currentTab !== undefined && !showtimeId) {
    return (
      <Navigate
        to={movie.urlPath}
        state={{ hasPrev: location.state?.hasPrev }}
        replace
      />
    )
  }

  return (
    <Flex
      direction="column"
      className="min-h-[var(--page-height)] flex-1 bg-white"
    >
      {helmet}
      <div className="flex-none">
        <MovieModalContent />
      </div>
      <Flex className="flex-1">
        <Grid
          columns={24}
          gutter={0}
          grow
          className="flex-1 mt-6 w-full h-full max-w-7xl px-2 mx-auto"
        >
          <Grid.Col className="h-full overflow-y-auto" span={12}>
            <SynopsisTab />
          </Grid.Col>
          <Grid.Col span={11} offset={1} className="overflow-y-auto h-full">
            <Stack className="h-full relative">
              <span className="ml-5">
                <Title fw={400} order={3} className="inline text-left">
                  Séances ({nbShowtimes})
                </Title>
              </span>
              {showtimesTab}
            </Stack>
          </Grid.Col>
        </Grid>
      </Flex>
    </Flex>
  )
}

export default function MoviePage() {
  const { tab, showtimeId } = useParams<RouteParams>()

  return (
    <CurrentMovieProvider>
      <MovieContent currentTab={showtimeId ? 'seances' : tab} />
    </CurrentMovieProvider>
  )
}
