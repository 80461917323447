import {
  formatShowtimeDate,
  formatShowtimeTime,
  showtimeIsAVP,
  showtimePriceText,
} from 'utils/showtimes'
import type { Movie, Showtime, Theater } from './showtimes'

const PRE_SHOWTIME_DURATION = 20

export default class ShowtimeContext {
  public showtime: Showtime
  public movie: Movie
  public theater: Theater
  constructor(showtime: Showtime, movie: Movie, theater: Theater) {
    this.showtime = showtime
    this.movie = movie
    this.theater = theater
  }

  get showtimeDateStr() {
    return formatShowtimeDate(this.showtime.showtime)
  }

  get showtimeTimeStr() {
    return formatShowtimeTime(this.showtime.showtime)
  }

  get endTime() {
    // Round up to the nearest 5 minutes
    const showtimeDuration =
      Math.ceil((this.movie.duration + PRE_SHOWTIME_DURATION) / 5) * 5
    return this.showtime.showtime.add(showtimeDuration, 'minute')
  }

  get price() {
    return showtimePriceText(this.theater, this.showtime)
  }

  get isAvantPremiere() {
    return showtimeIsAVP(this.showtime.showtime, this.movie.releaseDate)
  }

  get shareTitle() {
    return `${this.movie.titleVf}, ${this.theater.name} (${this.theater.city}) ${this.showtimeDateStr} à ${this.showtimeTimeStr}`
  }
}
