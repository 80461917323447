import { type ReactNode, createContext, useEffect, useMemo } from 'react'

import useNavigateBack from 'hooks/useNavigateBack'
import useTheaterById from 'queries/theaters'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { Theater } from 'types/showtimes'
import useValidContext from './common'
import { CurrentShowtimesProvider } from './currentShowtimesProvider'

type CurrentTheaterContextType = {
  theater: Theater
}

export const CurrentTheaterContext =
  createContext<CurrentTheaterContextType | null>(null)

export const useCurrentTheater = () => useValidContext(CurrentTheaterContext)

type RouteParams = {
  theaterId: string
  theaterUrlTitle: string
}

type CurrentTheaterProviderProps = {
  children: ReactNode | ReactNode[]
}

export function CurrentTheaterProvider({
  children,
}: CurrentTheaterProviderProps) {
  const { theaterId, theaterUrlTitle } = useParams<RouteParams>()
  const location = useLocation()
  const navigate = useNavigate()
  const navigateBack = useNavigateBack()

  const { data: theater, isError } = useTheaterById(theaterId)

  const contextValue = useMemo(() => (theater ? { theater } : null), [theater])

  useEffect(() => {
    if (isError) {
      navigateBack()
    }
  }, [isError, navigateBack])

  useEffect(() => {
    if (theater && theaterUrlTitle && theater.urlTitle !== theaterUrlTitle) {
      // Clean the URL when the theater's slug has changed
      const newPath = location.pathname.replace(
        `/${theaterUrlTitle}-`,
        `/${theater.urlTitle}-`
      )
      navigate(newPath, { replace: true })
    }
  }, [theater, theaterUrlTitle, location, navigate])

  if (!theater) {
    return null
  }

  return (
    <CurrentTheaterContext.Provider value={contextValue}>
      <CurrentShowtimesProvider>{children}</CurrentShowtimesProvider>
    </CurrentTheaterContext.Provider>
  )
}
