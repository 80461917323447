import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useNavigateBack from './useNavigateBack'

/**
 * Attach keyboard events to the Component
 * On Keydown :
 * - Escape : will navigate to the closeUrl
 * - Backspace: will navigate back to history
 */
export default function useNavigationKeyboardEvents(closeUrl: string) {
  const navigate = useNavigate()
  const navigateBack = useNavigateBack()

  // Add keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        navigate(closeUrl)
      } else if (event.key === 'Backspace') {
        navigateBack()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [navigate, navigateBack, closeUrl])
}
