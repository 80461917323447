import {
  Combobox,
  Group,
  Stack,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core'
import { useDebouncedValue, useViewportSize } from '@mantine/hooks'
/* eslint-disable react/jsx-props-no-spreading */
import {
  type ReactElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FaHeart } from 'react-icons/fa'
import { CurrentLocation, Location } from 'tabler-icons-react'

import trackEvent from 'queries/event'
import useLocationStore from 'states/useLocationStore'
import useSearchStore from 'states/useSearchStore'
import { DEFAULT_ADDRESS, FAVORITE_ADDRESS } from 'utils/utils'

interface Item {
  value: string
  id: string
  icon?: ReactElement | null
}

const favoriteItem: Item = {
  value: FAVORITE_ADDRESS,
  id: FAVORITE_ADDRESS,
  icon: <FaHeart size={16} className="text-red-700" />,
}
const defaultPositionItem: Item = {
  value: DEFAULT_ADDRESS,
  id: DEFAULT_ADDRESS,
  icon: <CurrentLocation size={16} />,
}

function AutoCompleteItem({ value, id, icon = null }: Item) {
  const addressElts = value.split(', ')
  const citySize = Math.min(2, addressElts.length - 1)
  let address: string
  let city: string | null
  if (citySize > 0) {
    address = addressElts.slice(0, -citySize).join(', ')
    city = addressElts.slice(-citySize).join(', ')
  } else {
    address = value
    city = null
  }

  return (
    <div key={id}>
      <Group wrap="nowrap">
        <div>{icon}</div>
        <Stack gap={1}>
          <Text size="sm" className="text-left" lineClamp={3}>
            {address}
          </Text>
          {city !== null && (
            <Text size="xs" className="text-left text-zinc-500">
              {city}
            </Text>
          )}
        </Stack>
      </Group>
    </div>
  )
}

function LocationInput() {
  const combobox = useCombobox()

  const place = useSearchStore((state) => state.place)
  const applySearchFavoriteTheaters = useSearchStore(
    (state) => state.applySearchFavoriteTheaters
  )
  const emptyFavoriteError = useSearchStore((state) => state.emptyFavoriteError)
  const { height: viewportHeight } = useViewportSize()
  const [placeText, setPlaceText] = useState(place.address)
  const [userInput, setUserInput] = useState('')
  const [debouncedUserInput] = useDebouncedValue(userInput, 300)

  const [nbItems, setNbItems] = useState(7)

  const [icon, setIcon] = useState<ReactElement | null>(null)
  const [options, setOptions] = useState<ReactElement[]>([])
  const [valueToId, setValueToId] = useState<Map<string, string>>(new Map())

  const updateIcon = (text: string) => {
    if (text === DEFAULT_ADDRESS) {
      setIcon(<CurrentLocation size={16} className="text-black" />)
    } else if (text === '') {
      setIcon(null)
    } else if (text === FAVORITE_ADDRESS) {
      setIcon(<FaHeart size={16} className="text-red-700" />)
    } else {
      setIcon(<Location size={16} className="text-black" />)
    }
  }
  const findLocation = useLocationStore((state) => state.findLocation)
  const placePredictions = useLocationStore((state) => state.placePredictions)
  const getPlacePredictions = useLocationStore(
    (state) => state.getPlacePredictions
  )

  useEffect(() => {
    if (viewportHeight >= 500) {
      setNbItems(7)
    } else {
      const newNbItems = Math.floor(viewportHeight - 200) / 75
      setNbItems(Math.max(2, newNbItems))
    }
  }, [viewportHeight])

  useEffect(() => {
    if (placeText === null) {
      setPlaceText(DEFAULT_ADDRESS)
      updateIcon(DEFAULT_ADDRESS)
    } else {
      updateIcon(placeText)
    }
  }, [placeText])

  useEffect(() => {
    // Ensure the new place is displayed after the user has selected it
    setPlaceText(place.address)
    updateIcon(place.address)
  }, [place])

  useEffect(() => {
    if (debouncedUserInput !== '') {
      getPlacePredictions(debouncedUserInput)
    }
  }, [debouncedUserInput, getPlacePredictions])

  const myRef = useRef<HTMLInputElement>(null)

  const autocompleteData = useMemo(() => {
    const defaultItems =
      placeText === '' ? [defaultPositionItem, favoriteItem] : []
    const predictionItems = placePredictions.map(
      (prediction: any): Item => ({
        icon: <Location size={16} />,
        value: String(prediction.description),
        id: String(prediction.place_id),
      })
    )
    return [...defaultItems, ...predictionItems]
  }, [placePredictions, placeText])

  useEffect(() => {
    const elements: ReactElement[] = []
    const idMap = new Map()
    autocompleteData.forEach((item) => {
      elements.push(
        <Combobox.Option value={item.value} key={item.id}>
          <AutoCompleteItem id={item.id} value={item.value} icon={item.icon} />
        </Combobox.Option>
      )
      idMap.set(item.value, item.id)
    })
    setOptions(elements.slice(0, nbItems))
    setValueToId(idMap)
  }, [autocompleteData, nbItems])

  useEffect(() => {
    if (!emptyFavoriteError) {
      return
    }
    setPlaceText(defaultPositionItem.value)
    updateIcon(defaultPositionItem.value)
    findLocation({
      address: defaultPositionItem.value,
      id: defaultPositionItem.id,
    })
  }, [emptyFavoriteError, findLocation])

  const handlePlaceChange = useCallback(
    (value: string) => {
      trackEvent('location_found', {
        location: value,
        searchText: debouncedUserInput,
      })
      if (value === FAVORITE_ADDRESS) {
        applySearchFavoriteTheaters()
      } else {
        const placeName = value.includes(',')
          ? value.split(',').slice(0, -1).join(',')
          : value
        setPlaceText(placeName)
        updateIcon(placeName)
        findLocation({ address: placeName, id: valueToId.get(value) || null })
      }
      if (myRef.current !== null) {
        // Lose focus on the input
        myRef.current.blur()
      }
    },
    [applySearchFavoriteTheaters, findLocation, debouncedUserInput, valueToId]
  )

  return (
    <Combobox
      size="md"
      store={combobox}
      onOptionSubmit={(value) => {
        handlePlaceChange(value)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target ref={myRef}>
        <TextInput
          placeholder="Où ?"
          onKeyDown={(e) => e.stopPropagation()}
          classNames={{
            input:
              'border-transparent focus:border-zinc-300 cursor-pointer text-ellipsis',
          }}
          size="md"
          className="text-lg ml-[5%] w-[95%]"
          onKeyUp={() => {
            setIcon(null)
            setUserInput(placeText)
          }}
          onFocus={() => {
            setIcon(null)

            setPlaceText(userInput)
            combobox.openDropdown()
          }}
          onBlur={() => {
            // Fill back the previous input when losing focus
            setPlaceText(place.address)
            updateIcon(place.address)
          }}
          value={placeText}
          leftSection={icon}
          onChange={(event) => {
            setPlaceText(event.currentTarget.value)
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
          }}
        />
      </Combobox.Target>
      <Combobox.Dropdown mah={viewportHeight - 200} className="overflow-auto">
        <Combobox.Options> {options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default memo(LocationInput)
