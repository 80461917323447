import { Container, CopyButton, Tooltip } from '@mantine/core'
import React, { useCallback } from 'react'

import trackEvent from 'queries/event'

type Props = {
  children: React.ReactNode
  className?: string
  url?: string | null
  text?: string | null
}

function ShareButton({
  children,
  className = '',
  url = null,
  text = null,
}: Props) {
  const shareTooltip =
    navigator.share === undefined ? 'Copier le lien' : 'Partager'
  const shareUrl = url || window.location.href

  const handleShare = useCallback(
    (copy: () => void) => {
      const shareText = text || document.title
      trackEvent('share', { title: shareText, url: shareUrl })
      if (navigator.share) {
        navigator.share({
          title: shareText,
          text: shareText,
          url: shareUrl,
        })
      } else {
        copy()
      }
    },
    [shareUrl, text]
  )

  return window.isSecureContext ? (
    <CopyButton value={shareUrl} timeout={2000}>
      {({ copied, copy }) => (
        <Tooltip
          label={copied ? 'Copié !' : shareTooltip}
          withArrow
          position="bottom"
          events={{ hover: true, focus: true, touch: true }}
          closeDelay={copied ? 500 : 100}
          className="cursor-pointer w-fit"
        >
          <Container
            onClick={() => handleShare(copy)}
            className={`cursor-pointer select-none px-0 mx-0 w-fit ${className}`}
          >
            {children}
          </Container>
        </Tooltip>
      )}
    </CopyButton>
  ) : null
}

export default React.memo(ShareButton)
