import { Affix, Notification, Transition } from '@mantine/core'
import { useTimeout } from '@mantine/hooks'
import trackEvent from 'queries/event'
import { useEffect, useState } from 'react'
import { FaHeartBroken, FaRegHeart } from 'react-icons/fa'
import useSearchStore from 'states/useSearchStore'

function EmptyFavoriteNotification() {
  const emptyFavoriteError = useSearchStore((state) => state.emptyFavoriteError)
  const [notification, setNotification] = useState(false)
  const unapplySearchFavoriteTheaters = useSearchStore(
    (state) => state.unapplySearchFavoriteTheaters
  )
  const { start } = useTimeout(() => setNotification(false), 5000)

  useEffect(() => {
    if (emptyFavoriteError) {
      setNotification(true)
      unapplySearchFavoriteTheaters()
      trackEvent('empty_favorite_notification', {})
      start()
    }
  }, [emptyFavoriteError, start, unapplySearchFavoriteTheaters])

  return (
    <Transition
      mounted={notification}
      transition="slide-left"
      timingFunction="ease"
      duration={300}
    >
      {(transitionStyles) => (
        <Affix
          position={{ top: '6rem', right: '2%' }}
          className="max-w-[90%]"
          style={{ ...transitionStyles }}
        >
          <Notification
            icon={<FaHeartBroken size="1rem" />}
            color="red"
            title="Aucun cinéma en favoris !"
            onClose={() => setNotification(false)}
            withBorder
          >
            Cliquez sur <FaRegHeart className="inline mb-1" /> à côté du nom
            d&apos;un cinéma pour l&apos;ajouter à vos favoris.
          </Notification>
        </Affix>
      )}
    </Transition>
  )
}

export default EmptyFavoriteNotification
