import { Anchor, Group, Image, Stack } from '@mantine/core'
import { useCurrentMovie } from 'providers/currentMovieProvider'
import trackEvent from 'queries/event'
import { useCallback } from 'react'
import type { MovieKind } from 'types/showtimes'
import {
  buildMovieRatingUrlFor,
  buildRatingDisplayFor,
  getMaxRatingFor,
} from './MovieRating.Helpers'

type Props = {
  kind: MovieKind
}

export default function MovieRating({ kind }: Props) {
  const { movie } = useCurrentMovie()

  const trackClick = useCallback(() => {
    trackEvent('rating_click', {
      id: movie.id,
      movieName: movie.titleVf,
      website: kind,
    })
  }, [movie.id, movie.titleVf, kind])

  const id = movie[`${kind}Id`]

  if (!id) {
    return null
  }

  const url = buildMovieRatingUrlFor(kind, id)
  if (url === undefined) {
    return null
  }

  const ratingDisplay = buildRatingDisplayFor(movie, kind)
  const maxRating = getMaxRatingFor(kind)

  return (
    <Anchor
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={trackClick}
      style={{
        fontFamily: 'Josefin Sans, sans-serif',
      }}
    >
      <Stack gap={2} align="center" className="text-black font-normal">
        <div className="w-full h-[30px]">
          <Image src={`/${kind}.png`} h={30} w={30} alt={kind} />
        </div>
        <Group gap={2} className="mb-0 mt-auto">
          <span className="text-sm">{ratingDisplay}</span>
          <span className="text-xs">/{maxRating}</span>
        </Group>
      </Stack>
    </Anchor>
  )
}
